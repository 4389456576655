import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import { ApiUrl } from "../../../../Constants";
import imageCompression from "browser-image-compression";


function EditProduct() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [categorylist, setCategorylist] = useState([]);

  function refreshPage() {
    window.location.reload();
  }

  const [productInput, setProduct] = useState({
    category_id: "",
    name: "",
    description: "",
    selling_price: "",
    original_price: "",
    allergen: "",
    status: "",
  });

  const [picture, setPicture] = useState([]);
  const [error_list, setError] = useState([]);

  const handleInput = (e) => {
    e.persist();
    setProduct({ ...productInput, [e.target.name]: e.target.value });
  };

  async function handleImage(event) {
    setPicture({ image: event.target.files[0] });

    const imageFile = event.target.files[0];
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
  
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
  
      await setPicture({ image: compressedFile }); // write your own logic
    } catch (error) {
      console.log(error);
    }
  
  };

  const id = useParams();
  const product_id = id["id"];

  useEffect(() => {
    axios.get(`api/all-category`).then((res) => {
      if (res.data.status === 200) {
        setCategorylist(res.data.category);
      }
    });

    axios.get(`api/edit-product/${product_id}`).then((res) => {
      if (res.data.status === 200) {
        //console.log(res.data.product);
        setProduct(res.data.product);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        navigate("/user/products");
      }
      setLoading(false);
    });
  }, []);

  const updateProduct = (e) => {
    e.preventDefault();

    //Fotoğraflı ürün ekleme işleminde aşağıdaki yapı kullanılır.
    const formData = new FormData();
    if(picture.image)
    {
      formData.append("image", picture.image);
    }
    formData.append("category_id", productInput.category_id);
    formData.append("name", productInput.name);
    if(productInput.description)
    {
    formData.append("description", productInput.description);
    }
    if(productInput.selling_price)
    {
    formData.append("selling_price", productInput.selling_price);
    }
    if(productInput.original_price)
    {
    formData.append("original_price", productInput.original_price);
    }
    if(productInput.allergen)
    {
    formData.append("allergen", productInput.allergen);
    }
    formData.append("status", productInput.status);

    axios.post(`api/update-product/${product_id}`, formData).then((res) => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        setError([]);
        navigate("/user/products");
      } else if (res.data.status === 422) {
        swal("All fields are mandetory", "", "error");
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        navigate("/user/products");
      }
    });
  };

  if (loading) {
    return <h4>Loading Edit Product</h4>;
  }

  return (
    <>
      <div className="min-h-screen flex-1 min-w-0 bg-gray-100">
        <div className="mt-5 sm:mt-0">
          <div className="">
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form onSubmit={updateProduct} encType="multipart/form-data">
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Kategori Seç
                        </label>
                        <select
                          name="category_id"
                          onChange={handleInput}
                          value={productInput.category_id}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        >
                          <option>Kategori Seç</option>
                          {categorylist.map((item) => {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Ürün Adı*
                        </label>
                        <input
                          type="text"
                          name="name"
                          onChange={handleInput}
                          value={productInput.name}
                          id="name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="Descrip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Açıklama
                        </label>
                        <textarea
                          type="text"
                          name="description"
                          onChange={handleInput}
                          value={productInput.description}
                          id="Descrip"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-3">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Satış Fiyatı
                        </label>
                        <input
                          type="text"
                          name="selling_price"
                          onChange={handleInput}
                          value={productInput.selling_price}
                          id="name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                      </div>

                      {/* <div className="col-span-3">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Gerçek Fiyat
                        </label>
                        <input
                          type="text"
                          name="original_price"
                          onChange={handleInput}
                          value={productInput.original_price}
                          id="name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                      </div> */}
                      <div className="col-span-6">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Alerjen Madde Varsa Belirtiniz
                        </label>
                        <input
                          type="text"
                          name="allergen"
                          onChange={handleInput}
                          value={productInput.allergen}
                          id="name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Ürün Fotoğrafı
                        </label>
                        <input
                          type="file"
                          name="image"
                          onChange={handleImage}
                          id="name"
                          className="mt-1 block w-full  border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <img
                          src={`${ApiUrl}${productInput.image}`}
                          width="50px"
                          alt={productInput.name}
                        />
                      </div>
                      <div className="col-span-6">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Durum*
                        </label>
                        <select
                          name="status"
                          onChange={handleInput}
                          value={productInput.status}
                          id="name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        >
                          
                          <option value="1">Aktif</option>
                          <option value="0">Pasif</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 py-3 justify-center text-right sm:px-36">
                    <button
                      type="submit"
                      className="w-full justify-center rounded-md flex items-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                      Ürün Ekle
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditProduct;
