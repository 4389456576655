import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const EditUser = () => {
  const navigate = useNavigate();
  const [settingInput, setSetting] = useState([]);
  const [error, setError] = useState([]);

  const id = useParams();
  const user_id = id["id"];
  useEffect(() => {
    axios.get(`/api/edit-user/${user_id}`).then((res) => {
      if (res.data.status === 200) {
        setSetting(res.data.user);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
      }
    });
  }, []);

  const handleInput = (e) => {
    e.persist();
    //hedef ismi ve değerleri categoryınputun içerisine burada gönderiliyor
    setSetting({ ...settingInput, [e.target.name]: e.target.value });
  };

  const updateUser = (e) => {
    e.preventDefault();

    const data = settingInput;
    axios.post(`/api/update-user/${user_id}`, data).then((res) => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        setError([]);
      } else if (res.data.status === 422) {
        swal("All fields are mandetory", "", "error");
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        navigate("/user/categories");
        swal("Error", res.data.message, "error");
      }
    });
  };

  return (
    <>
      <div className="min-h-screen flex-1 min-w-0 bg-gray-100">
        <div className="mt-5 sm:mt-0">
          <div className="">
            <div className="mt-5 md:col-span-2 md:mt-0" key={settingInput.id}>
              <form onSubmit={updateUser}>
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-6">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Adınız Soyadınız*
                        </label>
                        <input
                          type="text"
                          name="name"
                          onChange={handleInput}
                          value={settingInput.name}
                          id="name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.name}
                        </label>
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="Descrip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          İşletme Adı*
                        </label>
                        <input
                          type="text"
                          name="companyname"
                          onChange={handleInput}
                          value={settingInput.companyname}
                          id="companyname"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.companyname}
                        </label>
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="Descrip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          İşletme Adresi
                        </label>
                        <textarea
                          type="text"
                          name="address"
                          onChange={handleInput}
                          value={settingInput.address}
                          id="address"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.address}
                        </label>
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="Descrip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Telefon Numarası*
                        </label>
                        <input
                          type="text"
                          name="tel"
                          onChange={handleInput}
                          value={settingInput.tel}
                          id="tel"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.tel}
                        </label>
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="Descrip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Masa Sayısı
                        </label>
                        <input
                          type="text"
                          name="deskcount"
                          onChange={handleInput}
                          value={settingInput.deskcount}
                          id="deskcount"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.deskcount}
                        </label>
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="Descrip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          QR Linki
                        </label>
                        <input
                          type="text"
                          name="qrlink"
                          onChange={handleInput}
                          value={settingInput.qrlink}
                          id="qrlink"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.qrlink}
                        </label>
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="Descrip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Firma İngilizce İsmi Qr link ile uyumlu olması önemli
                        </label>
                        <input
                          type="text"
                          name="companyengname"
                          onChange={handleInput}
                          value={settingInput.companyengname}
                          id="companyengname"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.companyengname}
                        </label>
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="Descrip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          QR Görüntülenme Sayısı
                        </label>
                        <input
                          type="text"
                          name="count"
                          onChange={handleInput}
                          value={settingInput.count}
                          id="count"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.count}
                        </label>
                      </div>

                      {/* <div className="col-span-6">
                        <label
                          htmlFor="Descrip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          QR Durumu(0:Değiştirilemez 1:Değiştirilebilir)
                        </label>
                        <input
                          type="text"
                          name="qr_status"
                          onChange={handleInput}
                          value={settingInput.qr_status}
                          id="qr_status"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.qr_status}
                        </label>
                      </div> */}

                      <div className="col-span-2">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          QR Durumu
                        </label>
                        <select
                          name="qr_status"
                          onChange={handleInput}
                          value={settingInput.qr_status}
                          id="qr_status"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        >
                          <option>Durum Seç</option>
                          <option value="1">Değiştirilebilir</option>
                          <option value="0">Değiştirilemez</option>
                        </select>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.qr_status}
                        </label>
                      </div>

                      
                      <div className="col-span-2">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Kullanıcı Durumu
                        </label>
                        <select
                          name="status"
                          onChange={handleInput}
                          value={settingInput.status}
                          id="status"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        >
                          <option>Durum Seç</option>
                          <option value="1">Aktif</option>
                          <option value="0">Pasif</option>
                        </select>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.status}
                        </label>
                      </div>


                      <div className="col-span-2">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Kullanıcı Seviye
                        </label>
                        <select
                          name="userlevel"
                          onChange={handleInput}
                          value={settingInput.userlevel}
                          id="userlevel"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        >
                          <option>Seviye Seç</option>
                          <option value="0">Başlangıç</option>
                          <option value="1">Standart</option>
                          <option value="2">Profesyonel</option>
                        </select>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.userlevel}
                        </label>
                      </div>
{/* 
                      <div className="col-span-6">
                        <label
                          htmlFor="Descrip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Kullanıcı Seviye(0:Başlangıç 1:Standart 2:Profesyonel
                          )
                        </label>
                        <input
                          type="text"
                          name="userlevel"
                          onChange={handleInput}
                          value={settingInput.userlevel}
                          id="userlevel"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.userlevel}
                        </label>
                      </div> */}

                      {/* <div className="col-span-6">
                        <label
                          htmlFor="Descrip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Kullanıcı Durum(0:Pasif 1:Aktif)
                        </label>
                        <input
                          type="text"
                          name="status"
                          onChange={handleInput}
                          value={settingInput.status}
                          id="status"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.status}
                        </label>
                      </div> */}


                    </div>
                  </div>
                  <div className="bg-gray-50 py-3 justify-center text-right sm:px-36">
                    <button
                      type="submit"
                      className="w-full justify-center rounded-md flex items-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                      Ayarla
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
