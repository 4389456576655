import React, { useEffect, useState } from 'react'
import {Route, Navigate, useNavigate} from 'react-router-dom';
import Register from "./components/Home/Register";
import User from "./components/User/User";
import Admin from "./components/Admin/Admin";
import axios from 'axios';
import swal from 'sweetalert';


export default function AdminPrivateRoute(){

  const navigate = useNavigate();

  //0=false  1=user  2=Admin
  const [Authenticated, setAuthenticated] = useState(0);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`api/checkingAdminAuthenticated`).then( res => {
      if(res.data['role'] === "admin")
      {
        setAuthenticated(2);
      }
      else if(res.data['role'] === "user")
      {
        setAuthenticated(1);
      }
      else
      {
        setAuthenticated(0);
      }
      setLoading(false);
    });
  
    return () => {
      setAuthenticated(0);
    }
  }, [])

  axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
    if(err.response.status === 401)
    {
      swal("Unauthorized",err.response.data.message,"warning");
      navigate('/');
    }
    return Promise.reject(err);
  });

  axios.interceptors.response.use(function(response) {
      return response;
    }, function (error) {
      if(error.response.status === 403) //Acces Denied
      {
        swal("Forbidden",error.response.data.message,"warning");
        navigate('/403');
      }
      else if(error.response.status === 404) //Page Not Found
      {
        swal("404 Error","URL/Page Not Found","warning");
        navigate('/404');
      }
      return Promise.reject(error);
    }
  );


  if(Loading)
  {
    return <h1>Loading...</h1>
  }

  if(Authenticated === 2)
  {
    return <Admin />
  }
  else if(Authenticated === 1)
  {
    return <User />
  }
  else{
    return <Navigate to="/login" />
  }
}

