import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

function Settings() {
  const navigate = useNavigate();
  const [settingInput, setSetting] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    axios.get(`/api/edit-setting`).then((res) => {
      if (res.data.status === 200) {
        setSetting(res.data.settings);        
      } else if (res.data.status === 404) {
        console.log("Herhangi bir ayar bulunamadı");
      }
    });
  }, []);

  const handleInput = (e) => {
    e.persist();
    //hedef ismi ve değerleri categoryınputun içerisine burada gönderiliyor
    setSetting({ ...settingInput, [e.target.name]: e.target.value });
  };

  const updateCategory = (e) => {
    e.preventDefault();

    const data = settingInput;
    axios.post(`/api/update-setting`, data).then((res) => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        setError([]);
      }
      else if(res.data.status === 422)
      {
        swal("All fields are mandetory","", "error");
        setError(res.data.errors);
      }
      else if(res.data.status === 404)
      {
        navigate("/user/categories");
        swal("Error", res.data.message, "error");
        
      }

    });
  };
  return (
    <>
      <div className="min-h-screen flex-1 min-w-0 bg-gray-100" >
        <div className="mt-5 sm:mt-0">
          <div className="">
            <div className="mt-5 md:col-span-2 md:mt-0" key={settingInput.id}>
              <form onSubmit={updateCategory}>
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-6" >
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Adınız Soyadınız*
                        </label>
                        <input
                          type="text"
                          name="name"
                          onChange={handleInput}
                          value={settingInput.name}
                          id="name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.name}
                        </label>
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="Descrip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          İşletme Adı*
                        </label>
                        <input
                          type="text"
                          name="companyname"
                          onChange={handleInput}
                          value={settingInput.companyname}
                          id="companyname"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.companyname}
                        </label>
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="Descrip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          İşletme Adresi
                        </label>
                        <textarea
                          type="text"
                          name="address"
                          onChange={handleInput}
                          value={settingInput.address}
                          id="address"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.address}
                        </label>
                      </div>


                      <div className="col-span-6">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Şehir*
                        </label>
                        <select
                          name="city"
                          onChange={handleInput}
                          value={settingInput.city}
                          id="city"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        >
                          <option value="0">Şehir Seç</option>
                          <option value="1">Adana</option>
                          <option value="2">Adıyaman</option>
                          <option value="3">Afyonkarahisar</option>
                          <option value="4">Ağrı</option>
                          <option value="5">Amasya</option>
                          <option value="6">Ankara</option>
                          <option value="7">Antalya</option>
                          <option value="8">Artvin</option>
                          <option value="9">Aydın</option>
                          <option value="10">Balıkesir</option>
                          <option value="11">Bilecik</option>
                          <option value="12">Bingöl</option>
                          <option value="13">Bitlis</option>
                          <option value="14">Bolu</option>
                          <option value="15">Burdur</option>
                          <option value="16">Bursa</option>
                          <option value="17">Çanakkale</option>
                          <option value="18">Çankırı</option>
                          <option value="19">Çorum</option>
                          <option value="20">Denizli</option>
                          <option value="21">Diyarbakır</option>
                          <option value="22">Edirne</option>
                          <option value="23">Elazığ</option>
                          <option value="24">Erzincan</option>
                          <option value="25">Erzurum</option>
                          <option value="26">Eskişehir</option>
                          <option value="27">Gaziantep</option>
                          <option value="28">Giresun</option>
                          <option value="29">Gümüşhane</option>
                          <option value="30">Hakkâri</option>
                          <option value="31">Hatay</option>
                          <option value="32">Isparta</option>
                          <option value="33">Mersin</option>
                          <option value="34">İstanbul</option>
                          <option value="35">İzmir</option>
                          <option value="36">Kars</option>
                          <option value="37">Kastamonu</option>
                          <option value="38">Kayseri</option>
                          <option value="39">Kırklareli</option>
                          <option value="40">Kırşehir</option>
                          <option value="41">Kocaeli</option>
                          <option value="42">Konya</option>
                          <option value="43">Kütahya</option>
                          <option value="44">Malatya</option>
                          <option value="45">Manisa</option>
                          <option value="46">Kahramanmaraş</option>
                          <option value="47">Mardin</option>
                          <option value="48">Muğla</option>
                          <option value="49">Muş</option>
                          <option value="50">Nevşehir</option>
                          <option value="51">Niğde</option>
                          <option value="52">Ordu</option>
                          <option value="53">Rize</option>
                          <option value="54">Sakarya</option>
                          <option value="55">Samsun</option>
                          <option value="56">Siirt</option>
                          <option value="57">Sinop</option>
                          <option value="58">Sivas</option>
                          <option value="59">Tekirdağ</option>
                          <option value="60">Tokat</option>
                          <option value="61">Trabzon</option>
                          <option value="62">Tunceli</option>
                          <option value="63">Şanlıurfa</option>
                          <option value="64">Uşak</option>
                          <option value="65">Van</option>
                          <option value="66">Yozgat</option>
                          <option value="67">Zonguldak</option>
                          <option value="68">Aksaray</option>
                          <option value="69">Bayburt</option>
                          <option value="70">Karaman</option>
                          <option value="71">Kırıkkale</option>
                          <option value="72">Batman</option>
                          <option value="73">Şırnak</option>
                          <option value="74">Bartın</option>
                          <option value="75">Ardahan</option>
                          <option value="76">Iğdır</option>
                          <option value="77">Yalova</option>
                          <option value="78">Karabük</option>
                          <option value="79">Kilis</option>
                          <option value="80">Osmaniye</option>
                          <option value="81">Düzce</option>
                        </select>
                      </div>



                      <div className="col-span-6">
                        <label
                          htmlFor="Descrip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Telefon Numarası*
                        </label>
                        <input
                          type="text"
                          name="tel"
                          onChange={handleInput}
                          value={settingInput.tel}
                          id="tel"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.tel}
                        </label>
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="Descrip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Masa Sayısı
                        </label>
                        <input
                          type="text"
                          name="deskcount"
                          onChange={handleInput}
                          value={settingInput.deskcount}
                          id="deskcount"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.deskcount}
                        </label>
                      </div>


                    </div>
                  </div>
                  <div className="bg-gray-50 py-3 justify-center text-right sm:px-36">
                    <button
                      type="submit"
                      className="w-full justify-center rounded-md flex items-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                      Ayarla
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
