import { LockClosedIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import axios from "axios";
import logo from "./../../assets/menubum.png";
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';

export default function Register() {
  // Hooks

  const navigate = useNavigate();

  //checkbox kontrol işlemi burada yapılır
  const [checked, setChecked] = useState(false); 
  const handleChange = () => {    
    setChecked(!checked);      
  }; 
  console.log(checked);

  const [registerInput, setRegister] = useState({
    name: "",
    email: "",
    password: "",
    error_list: [],
  });

  const handleInput = (e) => {
    e.persist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const registerSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: registerInput.name,
      company: registerInput.company,
      address: registerInput.address,
      number: registerInput.number,
      email: registerInput.email,
      password: registerInput.password,
    };

    if(checked === true)
    {
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios.post("/api/register", data).then((res) => {
          if(res.data.status === 200){
            localStorage.setItem('auth_token', res.data.token);
            localStorage.setItem('auth_name', res.data.username);
            swal("Success",res.data.message,"success");
            navigate('/login');
          }else{
            setRegister({...registerInput, error_list: res.data.validation_errors});
          }
        });
      });
    }
    else
    {
      swal("Uyarı","Lütfen Kullanım Sözleşmesini Okuyup Onaylayın","warning");
    }

  };

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-20 w-auto"
              src={logo}
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Kayıt Ol
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Veya{" "}
              <a
                href="login"
                className="font-medium text-red-600 hover:text-red-500"
              >
                Hesabınıza giriş yapalım
              </a>
            </p>
          </div>
          <form
            className="mt-8 space-y-6"
            onSubmit={registerSubmit}
            action="#"
            method="POST"
          >
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="text" className="sr-only">
                  Ad Soyad
                </label>

                <input
                  id="name"
                  name="name"
                  onChange={handleInput}
                  value={registerInput.name}
                  type="text"
                  autoComplete="name"
                  className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                  placeholder="Adınız Soyadınız"
                />
                <span>{registerInput.error_list.name}</span>
              </div>
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  onChange={handleInput}
                  value={registerInput.email}
                  type="email"
                  autoComplete="email"
                  className="relative block mt-3 w-full appearance-none rounded-md  border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                  placeholder="E-Posta Adresiniz"
                />
                <span>{registerInput.error_list.email}</span>
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  onChange={handleInput}
                  value={registerInput.password}
                  type="password"
                  className="relative block mt-3 w-full appearance-none rounded-md  border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                  placeholder="Şifreniz"
                />
                <span>{registerInput.error_list.password}</span>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="check"
                  name="check"
                  type="checkbox"
                  onChange={handleChange}
                  className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
                />
                <a target="_blank" href="kullanim-sozlesmesi" className="ml-2 font-medium block text-sm text-red-600 hover:text-red-500">
                  Kullanım Sözleşmesini okudum ve kabul ediyorum
                </a>
              </div>

            </div>
            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-red-500 group-hover:text-red-400"
                    aria-hidden="true"
                  />
                </span>
                Kayıt Ol
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
