import React from 'react'
import Home from './pages/home/Home';
import Categories from './pages/categories/Categories';
import EditCategory from './pages/categories/EditCategory';
import {Routes, Route } from 'react-router-dom';
import Products from './pages/products/Products';
import Settings from './pages/settings/Settings';
import EditProduct from './pages/products/EditProduct';
import QR from './pages/qr/QR';


function Admin() {
  return (
    <div className=''>
      
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/categories" element={<Categories />} />
      <Route path="/categories/edit-category/:id" element={<EditCategory />} />
      <Route path="/products/edit-product/:id" element={<EditProduct />} />
      <Route path="/products" element={<Products />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/qr" element={<QR />} />
      </Routes>
    </div>
  );
}

export default Admin;