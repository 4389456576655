import React from 'react'
import axios from "axios";
import swal from "sweetalert";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

const User = () => {

  const [loading, setLoading] = useState(true);

  const [userlist, setUserlist] = useState([]);

  useEffect(() => {
    axios.get(`/api/view-user`).then((res) => {
      if (res.status === 200) {
        setUserlist(res.data.user);
      }
      setLoading(false);
    });
  }, []);

  console.log(userlist);

  var viewuser_HTMLTABLE = "";
  if (loading) {
    return <h4>Loading Category</h4>;
  } else {
    viewuser_HTMLTABLE = userlist.map((item) => {
      return (
        <>
          <tr className="bg-gray-50" key={item.id}>
            <td className="p-3 text-sm text-gray-700">{item.user_id}</td>
            <td className="p-3 text-sm text-gray-700">{item.name}</td>
            <td className="p-3 text-sm text-gray-700">{item.companyname}</td>
            <td className="p-3 text-sm text-gray-700">{item.address}</td>
            <td className="p-3 text-sm text-gray-700">{item.city}</td>
            <td className="p-3 text-sm text-gray-700">{item.tel}</td>
            <td className="p-3 text-sm text-gray-700">{item.deskcount}</td>
            <td className="p-3 text-sm text-gray-700">
              <a target="_blank" href={`${item.qrlink}`}  >{item.qrlink}</a>
            </td>
            <td className="p-3 text-sm text-gray-700">{item.count}</td>
            <td className="p-3 text-sm text-gray-700">{item.qr_status ? "Değiştirilebir" : "Değiştirilemez"}</td>
            <td className="p-3 text-sm text-gray-700">{item.status ? "Aktif" : "Pasif"}</td>
            <td className="p-3 text-sm text-gray-700">{item.userlevel === '0' ? "Başlangıç" : item.userlevel === '1' ? "Standart" : "Profesyonel"}</td>
            <td className="p-3 text-sm text-gray-700">{item.created_at}</td>
            <td>
              <Link
                to={`edit-user/${item.user_id}`}
                className="p-1.5 text-xs font-medium tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50"
              >
                Düzenle
              </Link>
            </td>

          </tr>
        </>
      );
    });
  }


  return (
    <>
      <div className="min-h-screen flex-1 min-w-0 bg-gray-200">
        <div className="max-w-7xl mx-auto px-2 sm:px-2 lg:px-2 pt-4">
          <div className="text-xl mb-2 flex items-center justify-between">
            <h1 className="py-auto">Kullanıcılar</h1>


          </div>

          <div className="rounded-sm">
            <table className="w-full">
              <thead className="bg-gray-50 border-b-2">
                <tr>

                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    ID
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Ad
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Firma Adı
                  </th>
                  <th className="w-2 p-3 text-sm font-semibold tracking-wide text-left">
                    Adres
                  </th>
                  <th className="w-2 p-3 text-sm font-semibold tracking-wide text-left">
                    Şehir
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Tel
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Masa Sayısı
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    QR
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Görüntülenme Sayısı
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    QR Durum
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Kullanıcı Durum
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Seviye
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Hesap Açılış Tarihi
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Düzenle
                  </th>

                </tr>
              </thead>

              <tbody>{viewuser_HTMLTABLE}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default User