import React from "react";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../Common/Loading";
import { ApiUrl } from "../../Constants";


const ViewProduct = () => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);

  const name = useParams();
  const category_id = name["id"];

  useEffect(() => {
    let isMounted = true;

    axios.get(`/api/getProduct/${category_id}`).then((res) => {
      if (isMounted) {
        if (res.data.status === 200) {
          setProduct(res.data.product_data.product);
          setCategory(res.data.product_data.category);
          setLoading(false);
        } else if (res.data.status === 404) {
          //burayı ayarla işte kategori bulunamadı filan de
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  if (loading) {
    return <Loading />;
  } else {
    var showProductList = "";
    showProductList = product.map((item) => {
      return (
        <>
          <div className="flex justify-center py-5 bg-gray-200">
            <div
              key={item.id}
              className="flex flex-col md:flex-row md:max-w-xl rounded-lg shadow-lg w-8/12 "
            >
              {item.image ? (
                <img
                  className="w-full h-36 md:h-40 object-cover md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg"
                  src={`${ApiUrl}/${item.image}`}
                  alt=""
                />
              ) : null}

              <div className="p-6 flex flex-col justify-start">
                {item.name ? (
                  <h5 className="text-gray-900 text-xl font-medium ">
                    {item.name}
                  </h5>
                ) : null}
                {item.description ? (
                  <p className="text-gray-700 text-base">{item.description}</p>
                ) : null}
                <p className="text-red-700 text-xl">{item.selling_price}</p>
              </div>
            </div>
          </div>
        </>
      );
    });
  }
  return (
    <>
      <div className="flex justify-center pt-5 pb-2 bg-gray-200">
        <h5 className="text-gray-900 text-xl font-medium">{category.name}</h5>
      </div>

      <div>{showProductList}</div>
    </>
  );
};

export default ViewProduct;
