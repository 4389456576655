import React from 'react'
import Home from './pages/home/Home';
import Categories from './pages/categories/Categories';
import User from './pages/user/User';
import EditUser from './pages/user/EditUser';
import {Routes, Route } from 'react-router-dom';


function Admin() {
  return (
    <div className='app red'>
      
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/categories" element={<Categories />} />
      <Route path="/users" element={<User />} />
      <Route path="/users/edit-user/:id" element={<EditUser />} />
      </Routes>
    </div>
  );
}

export default Admin;