import React from 'react'

const Main = () => {
  return (
        <div className='flex-1 min-w-0 bg-red-600'>
            Main
        </div>   
  )
}

export default Main