import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

export default function Cta() {
  const navigate = useNavigate();
  const [contactInput, setContact] = useState([]);
  const [error, setError] = useState([]);

  const handleInput = (e) => {
    e.persist();
    setContact({ ...contactInput, [e.target.name]: e.target.value });
  };

  const sendMessage = (e) => {
    e.preventDefault();

    const data = contactInput;
    axios.post(`/api/store-message`, data).then((res) => {
      if (res.data.status === 200) {
        swal("Success", "Mesaj Başarıyla Gönderildi", "success");
        navigate("/");
        setError([]);
      } else if (res.data.status === 400) {
        swal("Eksik Alanları Doldurunuz", "", "error");
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
      }
    });
  };

  return (
    <div id="cta" className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-8 px-6 lg:col-span-2 lg:px-8 lg:py-12 xl:pr-12">
          <div className="mx-auto max-w-lg">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              İletişim
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Size en kısa sürede ulaşacağız...
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div className="mt-6">
                <dt className="sr-only">İletişim Numaramız</dt>
                <dd className="flex">
                  <PhoneIcon
                    className="h-6 w-6 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">0 (538) 681-3038</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <EnvelopeIcon
                    className="h-6 w-6 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">tuncer.fm@gmail.com</span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-8 px-6 lg:col-span-3 lg:py-12 lg:px-8 xl:pl-12">
          <div className="mx-auto max-w-lg lg:max-w-none">
            <form onSubmit={sendMessage} className="grid grid-cols-1 gap-y-6">
              <div>
                <label htmlFor="full-name" className="sr-only">
                  Adınız Soyadınız
                </label>
                <input
                  type="text"
                  name="name"
                  onChange={handleInput}
                  value={contactInput.name}
                  autoComplete="name"
                  className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
                  placeholder="Adınız Soyadınız"
                />
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-red-700"
                >
                  {error.name}
                </label>
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Mail Adresiniz
                </label>
                <input
                  onChange={handleInput}
                  value={contactInput.mail}
                  name="mail"
                  type="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
                  placeholder="Mail Adresiniz"
                />
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-red-700"
                >
                  {error.mail}
                </label>
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  İletişim Numaranız
                </label>
                <input
                  
                  type="tel"
                  name="tel"
                  id="tel"
                  onChange={handleInput}
                  value={contactInput.tel}
                  autoComplete="tel"
                  className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
                  placeholder="İletişim Numaranız"
                />
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-red-700"
                >
                  {error.tel}
                </label>
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Mesajınız
                </label>
                <textarea
                  id="message"
                  name="message"
                  onChange={handleInput}
                  value={contactInput.message}
                  rows={4}
                  className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
                  placeholder="Mesajınız"
                />
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-red-700"
                >
                  {error.message}
                </label>
              </div>
              <div>
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-red-600 py-3 px-6 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  Gönder
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
