import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

export default function Contact() {
  const navigate = useNavigate();
  const [contactInput, setContact] = useState([]);
  const [error, setError] = useState([]);

  const handleInput = (e) => {
    e.persist();
    setContact({ ...contactInput, [e.target.name]: e.target.value });
  };

  const sendMessage = (e) => {
    e.preventDefault();

    const data = contactInput;
    axios.post(`/api/store-message`, data).then((res) => {
      if (res.data.status === 200) {
        swal("Success", "Mesaj Başarıyla Gönderildi", "success");
        navigate("/");
        setError([]);
      } else if (res.data.status === 400) {
        swal("Eksik Alanları Doldurunuz", "", "error");
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        
        swal("Error", res.data.message, "error");
      }
    });
  };

  return (
    <div className="overflow-hidden bg-white py-8 px-6 lg:px-8 lg:py-12">
      <div className="relative mx-auto max-w-xl">
        <svg
          className="absolute left-full translate-x-1/2 transform"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <svg
          className="absolute right-full bottom-0 -translate-x-1/2 transform"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Menubu İletişim Hizmetleri
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            Size en kısa sürede ulaşacağız...
          </p>
        </div>
        <div className="mt-12">
          <form
            onSubmit={sendMessage}
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Adınız
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  onChange={handleInput}
                  value={contactInput.name}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-red-500 focus:ring-red-500"
                />
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-red-700"
                >
                  {error.name}
                </label>
              </div>
            </div>
            <div>
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700"
              >
                Soyadınız
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="surname"
                  onChange={handleInput}
                  value={contactInput.surname}
                  autoComplete="family-name"
                  className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-red-500 focus:ring-red-500"
                />
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-red-700"
                >
                  {error.surname}
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="company"
                className="block text-sm font-medium text-gray-700"
              >
                Şirket
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="companyname"
                  onChange={handleInput}
                  value={contactInput.companyname}
                  autoComplete="organization"
                  className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-red-500 focus:ring-red-500"
                />
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-red-700"
                >
                  {error.companyname}
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Mail Adresiniz
              </label>
              <div className="mt-1">
                <input
                  name="mail"
                  type="email"
                  onChange={handleInput}
                  value={contactInput.mail}
                  autoComplete="email"
                  className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-red-500 focus:ring-red-500"
                />
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-red-700"
                >
                  {error.mail}
                </label>
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="tel"
                className="block text-sm font-medium text-gray-700"
              >
                Telefon
              </label>
              <div className="mt-1">
                <input
                  id="tel"
                  name="tel"
                  type="tel"
                  onChange={handleInput}
                  value={contactInput.tel}
                  autoComplete="tel"
                  className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-red-500 focus:ring-red-500"
                />
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-red-700"
                >
                  {error.tel}
                </label>
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700"
              >
                Mesajınız
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  onChange={handleInput}
                  value={contactInput.message}
                  rows={4}
                  className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-red-500 focus:ring-red-500"
                  defaultValue={""}
                />
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-red-700"
                >
                  {error.message}
                </label>
              </div>
            </div>

            <div className="sm:col-span-2">
              <button
                type="submit"
                className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-red-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Gönder
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
