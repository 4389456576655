import React, { useEffect, useState } from "react";
import Categories from "./ViewCategory";
import { useNavigate, useParams, Link } from "react-router-dom";

const FrontendHome = () => {

  //navigate ve use effect kaldırılınca ana menü tasarımı oluşur
  const navigate = useNavigate();
  useEffect(() => {
    navigate("category");
  }, []);


  return (
    <>
      <div className="flex min-h-full items-center justify-center pt-40  px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <Link
              to={`category`}
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            >
              Menü
            </Link>
          </div>
        </div>
      </div>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <Link
              to={`category`}
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            >
              Anket
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default FrontendHome;
