import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import { ApiUrl } from "../../../../Constants";
import imageCompression from "browser-image-compression";


function EditCategory() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [categoryInput, setCategory] = useState({
    name: "",
    description: "",
    status: "",
  });

  const [error, setError] = useState([]);

  const id = useParams();
  const category_id = id["id"];

  useEffect(() => {
    axios.get(`/api/edit-category/${category_id}`).then((res) => {
      if (res.data.status === 200) {
        setCategory(res.data.category);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        navigate("/user/categories");
      }
      setLoading(false);
    });
  }, []);

  const [picture, setPicture] = useState([]);
  async function handleImage(event) {
    setPicture({ image: event.target.files[0] });

    const imageFile = event.target.files[0];
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
  
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
  
      await setPicture({ image: compressedFile }); // write your own logic
    } catch (error) {
      console.log(error);
    }
  
  };

  const handleInput = (e) => {
    e.persist();
    //hedef ismi ve değerleri categoryınputun içerisine burada gönderiliyor
    setCategory({ ...categoryInput, [e.target.name]: e.target.value });
  };

  const updateCategory = (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (picture.image) {
      formData.append("image", picture.image);
    }
    formData.append("name", categoryInput.name);
    
    if (categoryInput.description) {
      formData.append("description", categoryInput.description);
    }
    formData.append("status", categoryInput.status);

    axios.post(`/api/update-category/${category_id}`, formData).then((res) => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        setError([]);
        navigate("/user/categories");
      } else if (res.data.status === 422) {
        swal("All fields are mandetory", "", "error");
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        navigate("/user/categories");
        swal("Error", res.data.message, "error");
      }
    });
  };

  if (loading) {
    return <h4>Loading Edit Category</h4>;
  }

  return (
    <>
      <div className="min-h-screen flex-1 min-w-0 bg-gray-100">
        <div className="mt-5 sm:mt-0">
          <div className="">
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form onSubmit={updateCategory}>
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Kategori Adı*
                        </label>
                        <input
                          type="text"
                          name="name"
                          onChange={handleInput}
                          value={categoryInput.name}
                          id="name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.name}
                        </label>
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="Descrip"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Açıklama
                        </label>
                        <textarea
                          type="text"
                          name="description"
                          onChange={handleInput}
                          value={categoryInput.description}
                          id="description"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-red-700"
                        >
                          {error.description}
                        </label>
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Ürün Fotoğrafı
                        </label>
                        <input
                          type="file"
                          name="image"
                          onChange={handleImage}
                          id="name"
                          className="mt-1 block w-full  border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        />
                        <img
                          src={`${ApiUrl}${categoryInput.image}`}
                          width="200px"
                          alt={categoryInput.name}
                        />
                      </div>
                      <div className="col-span-6">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Durum*
                        </label>
                        <select
                          name="status"
                          onChange={handleInput}
                          value={categoryInput.status}
                          id="name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        >
                          <option value="1">Aktif</option>
                          <option value="0">Pasif</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 py-3 justify-center text-right sm:px-36">
                    <button
                      type="submit"
                      className="w-full justify-center rounded-md flex items-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                      Kategori Düzenle
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCategory;
