import React from 'react'
import Categories from '../../components/categories/App';
import Sidebar from '../../components/sidebar/Sidebar3'

const Home = () => {
  return (
    <>
    <div className='h-screen flex'>
    <Sidebar />
    <Categories/>
    </div>
    


    </>

  )
}

export default Home;