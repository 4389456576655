import React from 'react'
import NavBar from "./NavBar";
import Features from "./Features";
import Pricing from "./Pricing";
import Cta from "./Cta";
import Footer from "./Footer";
import Alert from './Alerts';
import How from './How';

const Home = () => {
  return (
    <>
    <Alert/>
    <NavBar />
    <Features />
    <How/>
    <Pricing />
    <Cta />
    <Footer />
    </>
  )
}

export default Home;