import React, { useState } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { BiHomeAlt, BiLogOut } from "react-icons/bi";
import {
  MdOutlineDashboard,
  MdProductionQuantityLimits,
  MdSettings,
  MdContactSupport,
  MdOutlineQrCode2
} from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";



const Sidebar3 = () => {
  const menus = [
    { id: 1, name: "Anasayfa", link: "/admin/", icon: BiHomeAlt },
    { id: 2, name: "Kullanıcılar", link: "/admin/users", icon: MdOutlineQrCode2 },
    { id: 3, name: "Kategoriler", link: "/admin/categories", icon: MdProductionQuantityLimits, margin: false },
    { id: 4, name: "Ürünler", link: "/products", icon: MdOutlineDashboard },
    { id: 5, name: "Ayarlar", link: "/settings", icon: MdSettings },



    { id: 5, name: "İletişim", link: "/contact", icon: MdContactSupport, margin: true },
  ];
  const [open, setOpen] = useState(true);

  const navigate = useNavigate();
  const logoutSubmit = (e) => {
    e.preventDefault();

    axios.post("api/logout").then((res) => {
      if (res.data.status === 200) {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth_name");
        swal("Success", res.data.message, "success");
        navigate("/");
      } else {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth_name");
        swal("Success", "Başarıyla Çıkış Yapıldı", "success");
        navigate("/");
      }
    });
  };


  return (
    <section className="flex gap-6">
      <div className={`bg-slate-800 min-h-screen ${open ? 'w-60' : 'w-16'} text-gray-100 px-3`}>
        <div className="py-4 flex justify-end">
          <HiMenuAlt3 size={25} className="cursor-pointer  hover:bg-red-600 rounded-sm " onClick={() => setOpen(!open)} />
        </div>
        {menus.map((menu) => (
          <div key={menu.id} className="mt-4 flex flex-col gap-4 relative">
            <a
              to={menu?.link}
              href={menu?.link}
              className={`${menu?.margin && "mt-5"} group flex items-center text-sm gap-3.5 font-medium p-2 hover:bg-red-600 rounded-md`}
            >
              <div>
                <menu.icon className="h-6 w-6" />
              </div>
              <h2 className={`whitespace-pre duration-500 ${!open && 'opacity-0 translate-x-28 overflow-hidden'}`}>{menu?.name}</h2>
              <h2 className={`${open && "hidden "} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 
              rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300
              group-hover:w-fit`}>{menu?.name}</h2>
            </a>
          </div>
        ))}



        <div className="mt-4 flex flex-col gap-4 relative">
          <button
            onClick={logoutSubmit}
            className={`group flex items-center text-sm gap-3.5 font-medium p-2 hover:bg-red-600 rounded-md`}
          >
            <div>
              <BiLogOut className="h-6 w-6" />
            </div>
            <h2
              className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                }`}
            >
              Çıkış yap
            </h2>
            <h2
              className={`${open && "hidden "
                } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 
              rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300
              group-hover:w-fit`}
            >
              Çıkış yap
            </h2>
          </button>
        </div>


      </div>

    </section>
  );
};

export default Sidebar3;
