import React from "react";

const gizlilik = () => {
  return (
    <>
      {/* <div className="relative overflow-hidden bg-white">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 bg-white pb-2 sm:pb-4 md:pb-5 lg:w-full lg:max-w-2xl lg:pb-7 xl:pb-8">
            <NavBarNav />
          </div>
        </div>
      </div> */}
      <div className="mx-auto max-w-7xl pt-4">
        <h1 className="text-2xl ">Gizlilik Politikası ve KVKK</h1>
        <p className="pt-4">
          Size Hizmetimizi sağlamak ve geliştirmek için çeşitli amaçlar için
          birkaç farklı türde bilgi topluyoruz.
        </p>

        <h1 className="text-xl pt-10">Toplanan Veriler</h1>
        <p className="pt-4">
          Hizmete nasıl erişildiği ve kullanıldığı hakkında bilgi toplayabiliriz
          . Bu Kullanım Verileri, bilgisayarınızın İnternet Protokolü adresi
          (örn. IP adresi), tarayıcı türü, tarayıcı sürümü, ziyaret ettiğiniz
          Hizmet sayfalarımız, ziyaretinizin saati ve tarihi, bu sayfalarda
          harcanan zaman, gibi bilgileri içerebilir.
        </p>

        <h1 className="text-xl pt-10">KVKK</h1>
        <p className="pt-4">
          6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) uyarınca,
          Şirketimiz tarafından, Veri Sorumlusu sıfatıyla, kişisel verileriniz,
          iş amaçlarıyla bağlı olarak, aşağıda açıklandığı çerçevede
          kullanılmak, kaydedilmek, saklanmak, güncellenmek, aktarılmak ve/veya
          sınıflandırılmak suretiyle işlenecektir. Bu kapsamda Şirketimiz
          tarafından başta özel hayatın gizliliği olmak üzere, kişilerin temel
          hak ve özgürlüklerini korumak ve kişisel verilerin korunması amacıyla
          düzenlenen Kanun ve Yönetmelikler gereğince Şirketimiz, kişisel
          verilerinizin hukuka aykırı olarak işlenmesini önleme, hukuka aykırı
          olarak erişilmesini önleme ve muhafazasını sağlama amacıyla, uygun
          güvenlik düzeyini temin etmeye yönelik tüm teknik ve idari tedbirleri
          almaktadır. Bu metnin hedef kitlesi, Şirketimiz çalışanları veya
          Şirketimize iş başvurusu yapmış olan çalışan adayları dışındaki,
          Şirketimiz tarafından kişisel verileri işlenen tüm gerçek kişilerdir.
          Kişisel verilerin işlenme amaçları ve hukuki sebepleri; Tarafınızca
          paylaşılan kişisel verileriniz; Şirketimiz tarafından sunulan ürün ve
          hizmetlerden sizleri ve/veya temsil ettiğiniz kurum ve kuruluşları
          faydalandırmak için, Şirketimizin ticari ve iş stratejilerinin
          belirlenmesi ve uygulanması, pazarlama faaliyetlerinin yapılması, iş
          geliştirme ve planlama faaliyetlerinin gerçekleştirilmesi dahil ve
          fakat bunlarla sınırlı olmamak üzere gerekli çalışmaların yürütülmesi,
          Şirketimiz tarafından yürütülen iletişime yönelik idari operasyonların
          yürütülmesi, Şirketimizin kullanımda olan lokasyonların fiziksel
          güvenliğinin ve denetiminin sağlanması, İş ortağı/müşteri/tedarikçi
          (yetkili veya çalışanları) ilişkilerinin kurulması, İş ortaklarımız,
          tedarikçilerimiz veya sair üçüncü kişilerle birlikte sunulan ürün ve
          hizmetlere ilişkin sözleşme gereklerinin ve finansal mutabakatın
          sağlanması, Şirketimizin insan kaynakları politikalarının yürütülmesi,
          Şirketimizin çağrı merkezinin aranması veya internet sayfasının
          kullanılması ve/veya Şirketimizin düzenlediği eğitim, seminer veya
          organizasyonlara katılım sağlanması amacıyla işlenecektir. Kişisel
          verilerin toplanma ve saklanma yöntemi; Şirketimizle paylaştığınız
          kişisel verileriniz, otomatik ya da otomatik olmayan yöntemlerle,
          ofisler, şubeler, çağrı merkezi, internet sitesi, sosyal medya
          mecraları, mobil uygulamalar ve benzeri vasıtalarla sözlü, yazılı ya
          da elektronik olarak toplanabilir. Kişisel verileriniz elektronik
          ve/veya fiziksel ortamlarda saklanacaktır. Şirketimiz tarafından temin
          edilen ve saklanan kişisel verilerinizin saklandıkları ortamlarda
          yetkisiz erişime maruz kalmamaları, manipülasyona uğramamaları,
          kaybolmamaları ve zarar görmemeleri amacıyla gereken iş süreçlerinin
          tasarımı ile teknik güvenlik altyapı geliştirmeleri uygulanmaktadır.
          Kişisel verileriniz, size bildirilen amaçlar ve kapsam dışında
          kullanılmamak kaydı ile gerekli tüm bilgi güvenliği tedbirleri de
          alınarak işlenecek ve yasal saklama süresince veya böyle bir süre
          öngörülmemişse işleme amacının gerekli kıldığı süre boyunca saklanacak
          ve işlenecektir. Bu süre sona erdiğinde, kişisel verileriniz silinme,
          yok edilme ya da anonimleştirme yöntemleri ile Şirketimizin veri
          akışlarından çıkarılacaktır. Kişisel Verilerin aktarılması; Kişisel
          verileriniz, Kanunlar ve sair mevzuat kapsamında ve açıklanan
          amaçlarla; İşlenip işlenmediğini öğrenme, İşlenmişse bilgi talep etme,
          İşlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme,
          Yurt içinde / yurt dışında aktarıldığı 3. kişileri bilme, Eksik /
          yanlış işlenmişse düzeltilmesini isteme, KVKK’nın 7. maddesinde
          öngörülen şartlar çerçevesinde silinmesini / yok edilmesini isteme,
          Aktarıldığı 3. kişilere yukarıda sayılan (e) ve (f) bentleri uyarınca
          yapılan işlemlerin bildirilmesini isteme, Münhasıran otomatik
          sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya
          çıkmasına itiraz etme, Kanuna aykırı olarak işlenmesi sebebiyle zarara
          uğramanız hâlinde zararın giderilmesini talep etme haklarına
          sahipsiniz. KVK Kanunu’nun 13. maddesinin 1. fıkrası gereğince,
          yukarıda belirtilen haklarınızı kullanmak ile ilgili talebinizi,
          yazılı olarak veya Kişisel Verileri Koruma Kurulu’nun belirlediği
          diğer yöntemlerle Şirketimize iletebilirsiniz. Yukarıda belirtilen
          haklarınızı kullanmak için kimliğinizi tespit edici gerekli bilgiler
          ile iletişim sayfası üzeriden, noter kanalıyla veya Kişisel Verileri
          Koruma Kurulu tarafından belirlenen diğer yöntemler ile
          gönderebilirsiniz.
        </p>
      </div>
    </>
  );
};

export default gizlilik;
