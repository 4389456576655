import React from "react";
import QRCode from "qrcode";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const QR = () => {
  
  const navigate = useNavigate();
  const [url, setUrl] = useState("");
  const [qrcode, setQrcode] = useState("");

  const GenerateQRCode = () => {
    QRCode.toDataURL(
      url,
      {
        width: 800,
        height: 600,
        margin: 1,
      },
      (err, url) => {
        if (err) return console.error(err);
        setQrcode(url);
      }
    );
  };

  useEffect(() => {
    axios.get(`/api/edit-setting`).then((res) => {
      if (res.data.status === 200) {
        setUrl(res.data.settings["qrlink"]);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "warning");
        navigate("/user/settings");
      }
    });
  }, []);

  return (
    <>
      <div className="min-h-screen flex-1 min-w-0 bg-gray-100">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
          <div className="text-xl mb-2 flex">
            <div class="flex h-96 w-96 flex-col items-center py-6  ">
              <div class="px-4 sm:px-6 ">
                <button
                  onClick={GenerateQRCode}
                  className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-red-900"
                >
                  QR Kodu Oluştur
                </button>
              </div>
              <div class="py-6 ">
                <div class="w-64 h-64 px-2 sm:px-">
                  <div
                    class="h-full w-full border-2 border-dashed border-gray-300"
                    aria-hidden="true"
                  >
                    {qrcode && (
                      <>
                        <img className="h-full w-full" src={qrcode} />
                      </>
                    )}
                  </div>
                </div>
              </div>
              {qrcode && (
                <>
                  <a
                    href={qrcode}
                    className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-red-900"
                    download="qrcode.png"
                  >
                    İndir
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QR;
