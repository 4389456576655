import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Loading from "../Common/Loading";
import { ApiUrl } from "../../Constants";


const ViewCategory = () => {
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState([]);

  const name = useParams();
  const company_name = name["company"].toLowerCase();

  useEffect(() => {
    let isMounted = true;

    axios.get(`/api/getCategory/${company_name}`).then((res) => {
      if (isMounted) {
        if (res.data.status === 200) {
          setCategory(res.data.category);
          setLoading(false);
        } else if (res.data.status === 404) {
          //burayı ayarla işte kategori bulunamadı filan de
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  if (loading) {
    return <Loading />;
  } else {
    var showCategoryList = "";
    showCategoryList = category.map((item) => {
      return (
        <>
          <div className="flex justify-center py-5 bg-gray-200">
            <Link
              to={`${item.id}`}
              key={item.id}
              className="flex flex-col md:flex-row md:max-w-xl rounded-lg shadow-lg w-8/12"
            >
              {item.image ? (
                <img
                  className="w-full h-36 md:h-40 object-cover md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg"
                  src={`${ApiUrl}/${item.image}`}
                  alt=""
                />
              ) : null}
              <div className="p-6 flex flex-col justify-start">
                {item.name ? (
                  <h5 className="text-gray-900 text-xl font-medium ">
                    {item.name}
                  </h5>
                ) : null}

                {item.image ? (
                  <p className="text-gray-700 text-base">{item.description}</p>
                ) : null}
              </div>
            </Link>
          </div>
        </>
      );
    });
  }

  return (
    <>
      <div className="flex justify-center pt-5 pb-2 bg-gray-200">
        <h5 className="text-gray-900 text-xl font-medium">Kategoriler</h5>
      </div>

      <div>{showCategoryList}</div>
    </>
  );
};

export default ViewCategory;
