import React from 'react'
import Settings from '../../components/settings/Settings'
import Sidebar from '../../components/sidebar/Sidebar3'

const Home = () => {
  return (
    <>
    <div className='h-screen flex'>
    <Sidebar />
    <Settings />
    </div>
    


    </>

  )
}

export default Home;