import React from 'react'
import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";

const Main = () => {

  const navigate = useNavigate();
  const [url, setUrl] = useState("");

  useEffect(() => {
    axios.get(`/api/edit-setting`).then((res) => {
      if (res.data.status === 200) {
        setUrl(res.data.settings["qrlink"]);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "warning");
        navigate("/user/settings");
      }
    });
  }, []);


  
  return (
    <>
        <div>Hoşgeldiniz</div>


  </>

  )
}

export default Main