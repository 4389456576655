import React from 'react'
import QR from '../../components/qr/QR'
import Sidebar from '../../components/sidebar/Sidebar3'

const Qr = () => {
  return (
    <>
    <div className='h-screen flex'>
    <Sidebar/>
    <QR/>
    
    </div>
    


    </>

  )
}

export default Qr;

