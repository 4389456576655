import React from "react";

const sozlesme = () => {
  return (
    <>
      {/* <div className="relative overflow-hidden bg-white">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 bg-white pb-2 sm:pb-4 md:pb-5 lg:w-full lg:max-w-2xl lg:pb-7 xl:pb-8">
            <NavBarNav />
          </div>
        </div>
      </div> */}
      <div className="mx-auto max-w-7xl pt-4">
        <h1 className="text-2xl ">Kullanım Sözleşmesi</h1>
        <p className="pt-4">
          Lütfen sitemizi kullanmadan evvel kullanım şartları’nı dikkatlice
          okuyunuz. Bu siteyi kullanan müşterilerimiz aşağıdaki şartları kabul
          etmiş varsayılmaktadır: Sizler (‘Kullanıcı’) sitede sunulan tüm
          hizmetleri kullanırken aşağıdaki şartlara tabi olduğunuzu, sitedeki
          hizmetten yararlanmakla ve kullanmaya devam etmekle; Bağlı olduğunuz
          yasalara göre sözleşme imzalama hakkına, yetkisine ve hukuki
          ehliyetine sahip ve 18 yaşın üzerinde olduğunuzu, bu sözleşmeyi
          okuduğunuzu, anladığınızı ve sözleşmede yazan şartlarla bağlı
          olduğunuzu kabul etmiş sayılırsınız. İşbu sözleşme taraflara sözleşme
          konusu site ile ilgili hak ve yükümlülükler yükler ve taraflar işbu
          sözleşmeyi kabul ettiklerinde bahsi geçen hak ve yükümlülükleri
          eksiksiz, doğru, zamanında, işbu sözleşmede talep edilen şartlar
          dâhilinde yerine getireceklerini beyan ederler.
        </p>

        <h1 className="text-xl pt-10">Sorumluluklar</h1>
        <p className="pt-4">
          Menubu, fiyatlar ve sunulan ürün ve hizmetler üzerinde değişiklik
          yapma hakkını her zaman saklı tutar.
        </p>
        <p className="pt-1">
          Firma, üyenin sözleşme konusu hizmetlerden, teknik arızalar dışında
          yararlandırılacağını kabul ve taahhüt eder.
        </p>
        <p className="pt-1">
          Kullanıcı, sitenin kullanımında tersine mühendislik yapmayacağını ya
          da bunların kaynak kodunu bulmak veya elde etmek amacına yönelik
          herhangi bir başka işlemde bulunmayacağını aksi halde ve 3. Kişiler
          nezdinde doğacak zararlardan sorumlu olacağını, hakkında hukuki ve
          cezai işlem yapılacağını peşinen kabul eder.
        </p>
        <p className="pt-1">
          Kullanıcı, site içindeki faaliyetlerinde, sitenin herhangi bir
          bölümünde veya iletişimlerinde genel ahlaka ve adaba aykırı, kanuna
          aykırı, 3. Kişilerin haklarını zedeleyen, yanıltıcı, saldırgan,
          müstehcen, pornografik, kişilik haklarını zedeleyen, telif haklarına
          aykırı, yasa dışı faaliyetleri teşvik eden içerikler üretmeyeceğini,
          paylaşmayacağını kabul eder. Aksi halde oluşacak zarardan tamamen
          kendisi sorumludur ve bu durumda ‘Site’ yetkilileri, bu tür hesapları
          askıya alabilir, sona erdirebilir, yasal süreç başlatma hakkını saklı
          tutar. Bu sebeple yargı mercilerinden etkinlik veya kullanıcı
          hesapları ile ilgili bilgi talepleri gelirse paylaşma hakkını saklı
          tutar.
        </p>
        <p className="pt-1">
          Sitenin üyelerinin birbirleri veya üçüncü şahıslarla olan ilişkileri
          kendi sorumluluğundadır.
        </p>

        <h1 className="text-xl pt-10">Fikri Mülkiyet Hakları</h1>
        <p className="pt-4">
          İşbu Site’de yer alan ünvan, işletme adı, marka, patent, logo,
          tasarım, bilgi ve yöntem gibi tescilli veya tescilsiz tüm fikri
          mülkiyet hakları site işleteni ve sahibi firmaya veya belirtilen
          ilgilisine ait olup, ulusal ve uluslararası hukukun koruması
          altındadır. İşbu Site’nin ziyaret edilmesi veya bu Site’deki
          hizmetlerden yararlanılması söz konusu fikri mülkiyet hakları
          konusunda hiçbir hak vermez.
        </p>
        <p className="pt-1">
          Site’de yer alan bilgiler hiçbir şekilde çoğaltılamaz, yayınlanamaz,
          kopyalanamaz, sunulamaz ve/veya aktarılamaz. Site’nin bütünü veya bir
          kısmı diğer bir internet sitesinde izinsiz olarak kullanılamaz.
        </p>

        <h1 className="text-xl pt-10">Gizli Bilgi</h1>
        <p className="pt-4">
          Site üzerinden kullanıcıların ilettiği kişisel bilgileri 3. Kişilere
          açıklamayacaktır. Bu kişisel bilgiler; kişi adı-soyadı, adresi,
          telefon numarası, cep telefonu, e-posta adresi gibi Kullanıcı’yı
          tanımlamaya yönelik her türlü diğer bilgiyi içermekte olup, kısaca
          ‘Gizli Bilgiler’ olarak anılacaktır.
        </p>
        <p className="pt-1">
          Kullanıcı, sadece tanıtım, reklam, kampanya, promosyon, duyuru vb.
          pazarlama faaliyetleri kapsamında kullanılması ile sınırlı olmak
          üzere, Site’nin sahibi olan firmanın kendisine ait iletişim, portföy
          durumu ve demografik bilgilerini iştirakleri ya da bağlı bulunduğu
          grup şirketleri ile paylaşmasına muvafakat ettiğini kabul ve beyan
          eder. Bu kişisel bilgiler firma bünyesinde müşteri profili belirlemek,
          müşteri profiline uygun promosyon ve kampanyalar sunmak ve
          istatistiksel çalışmalar yapmak amacıyla kullanılabilecektir.{" "}
        </p>
        <p className="pt-1">
          Gizli Bilgiler, ancak resmi makamlarca usulü dairesinde bu bilgilerin
          talep edilmesi halinde ve yürürlükteki emredici mevzuat hükümleri
          gereğince resmi makamlara açıklama yapılmasının zorunlu olduğu
          durumlarda resmi makamlara açıklanabilecektir.
        </p>

        <h1 className="text-xl pt-10">Garanti Vermeme</h1>
        <p className="pt-4">
          İŞBU SÖZLEŞME MADDESİ UYGULANABİLİR KANUNUN İZİN VERDİĞİ AZAMİ ÖLÇÜDE
          GEÇERLİ OLACAKTIR. MENUBU TARAFINDAN SUNULAN HİZMETLER "OLDUĞU GİBİ”
          VE "MÜMKÜN OLDUĞU” TEMELDE SUNULMAKTA VE PAZARLANABİLİRLİK, BELİRLİ
          BİR AMACA UYGUNLUK VEYA İHLAL ETMEME KONUSUNDA TÜM ZIMNİ GARANTİLER DE
          DÂHİL OLMAK ÜZERE HİZMETLER VEYA UYGULAMA İLE İLGİLİ OLARAK (BUNLARDA
          YER ALAN TÜM BİLGİLER DÂHİL) SARİH VEYA ZIMNİ, KANUNİ VEYA BAŞKA BİR
          NİTELİKTE HİÇBİR GARANTİDE BULUNMAMAKTADIR.
        </p>

        <h1 className="text-xl pt-10">Kayıt ve Güvenlik </h1>
        <p className="pt-4">
          Kullanıcı, doğru, eksiksiz ve güncel kayıt bilgilerini vermek
          zorundadır. Aksi halde bu Sözleşme ihlal edilmiş sayılacak ve
          kullanıcı bilgilendirilmeksizin hesap kapatılabilecektir. Kullanıcı,
          site ve üçüncü taraf sitelerdeki şifre ve hesap güvenliğinden kendisi
          sorumludur. Aksi halde oluşacak veri kayıplarından ve güvenlik
          ihlallerinden veya donanım ve cihazların zarar görmesinden MENUBU.COM
          sorumlu tutulamaz.
        </p>

        <h1 className="text-xl pt-10">Mücbir Sebep</h1>
        <p className="pt-4">
          Tarafların kontrolünde olmayan; tabii afetler, yangın, patlamalar, iç
          savaşlar, savaşlar, ayaklanmalar, halk hareketleri, seferberlik ilanı,
          grev, lokavt ve salgın hastalıklar, altyapı ve internet arızaları,
          elektrik kesintisi gibi sebeplerden (aşağıda birlikte "Mücbir Sebep”
          olarak anılacaktır.) dolayı sözleşmeden doğan yükümlülükler taraflarca
          ifa edilemez hale gelirse, taraflar bundan sorumlu değildir. Bu sürede
          Taraflar’ın işbu Sözleşme’den doğan hak ve yükümlülükleri askıya
          alınır.{" "}
        </p>

        <h1 className="text-xl pt-10">
          {" "}
          Sözleşmenin Bütünlüğü ve Uygulanabilirlik
        </h1>
        <p className="pt-4">
          İşbu sözleşme şartlarından biri, kısmen veya tamamen geçersiz hale
          gelirse, sözleşmenin geri kalanı geçerliliğini korumaya devam eder.
        </p>

        <h1 className="text-xl pt-10">
          {" "}
          Sözleşmede Yapılacak Değişiklikler
        </h1>
        <p className="pt-4">
        Firma, dilediği zaman sitede sunulan hizmetleri ve işbu sözleşme şartlarını kısmen veya tamamen değiştirebilir. Değişiklikler sitede yayınlandığı tarihten itibaren geçerli olacaktır. Değişiklikleri takip etmek Kullanıcı’nın sorumluluğundadır. Kullanıcı, sunulan hizmetlerden yararlanmaya devam etmekle bu değişiklikleri de kabul etmiş sayılır.
        </p>

        <h1 className="text-xl pt-10">
          {" "}Tebligat
        </h1>
        <p className="pt-4">
        İşbu Sözleşme ile ilgili taraflara gönderilecek olan tüm bildirimler, Firma’nın bilinen e.posta adresi ve kullanıcının üyelik formunda belirttiği e.posta adresi vasıtasıyla yapılacaktır. Kullanıcı, üye olurken belirttiği adresin geçerli tebligat adresi olduğunu, değişmesi durumunda 5 gün içinde yazılı olarak diğer tarafa bildireceğini, aksi halde bu adrese yapılacak tebligatların geçerli sayılacağını kabul eder.
        </p>


        <h1 className="text-xl pt-10">KVKK</h1>
        <p className="pt-4">
          6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) uyarınca,
          Şirketimiz tarafından, Veri Sorumlusu sıfatıyla, kişisel verileriniz,
          iş amaçlarıyla bağlı olarak, aşağıda açıklandığı çerçevede
          kullanılmak, kaydedilmek, saklanmak, güncellenmek, aktarılmak ve/veya
          sınıflandırılmak suretiyle işlenecektir. Bu kapsamda Şirketimiz
          tarafından başta özel hayatın gizliliği olmak üzere, kişilerin temel
          hak ve özgürlüklerini korumak ve kişisel verilerin korunması amacıyla
          düzenlenen Kanun ve Yönetmelikler gereğince Şirketimiz, kişisel
          verilerinizin hukuka aykırı olarak işlenmesini önleme, hukuka aykırı
          olarak erişilmesini önleme ve muhafazasını sağlama amacıyla, uygun
          güvenlik düzeyini temin etmeye yönelik tüm teknik ve idari tedbirleri
          almaktadır. Bu metnin hedef kitlesi, Şirketimiz çalışanları veya
          Şirketimize iş başvurusu yapmış olan çalışan adayları dışındaki,
          Şirketimiz tarafından kişisel verileri işlenen tüm gerçek kişilerdir.
          Kişisel verilerin işlenme amaçları ve hukuki sebepleri; Tarafınızca
          paylaşılan kişisel verileriniz; Şirketimiz tarafından sunulan ürün ve
          hizmetlerden sizleri ve/veya temsil ettiğiniz kurum ve kuruluşları
          faydalandırmak için, Şirketimizin ticari ve iş stratejilerinin
          belirlenmesi ve uygulanması, pazarlama faaliyetlerinin yapılması, iş
          geliştirme ve planlama faaliyetlerinin gerçekleştirilmesi dahil ve
          fakat bunlarla sınırlı olmamak üzere gerekli çalışmaların yürütülmesi,
          Şirketimiz tarafından yürütülen iletişime yönelik idari operasyonların
          yürütülmesi, Şirketimizin kullanımda olan lokasyonların fiziksel
          güvenliğinin ve denetiminin sağlanması, İş ortağı/müşteri/tedarikçi
          (yetkili veya çalışanları) ilişkilerinin kurulması, İş ortaklarımız,
          tedarikçilerimiz veya sair üçüncü kişilerle birlikte sunulan ürün ve
          hizmetlere ilişkin sözleşme gereklerinin ve finansal mutabakatın
          sağlanması, Şirketimizin insan kaynakları politikalarının yürütülmesi,
          Şirketimizin çağrı merkezinin aranması veya internet sayfasının
          kullanılması ve/veya Şirketimizin düzenlediği eğitim, seminer veya
          organizasyonlara katılım sağlanması amacıyla işlenecektir. Kişisel
          verilerin toplanma ve saklanma yöntemi; Şirketimizle paylaştığınız
          kişisel verileriniz, otomatik ya da otomatik olmayan yöntemlerle,
          ofisler, şubeler, çağrı merkezi, internet sitesi, sosyal medya
          mecraları, mobil uygulamalar ve benzeri vasıtalarla sözlü, yazılı ya
          da elektronik olarak toplanabilir. Kişisel verileriniz elektronik
          ve/veya fiziksel ortamlarda saklanacaktır. Şirketimiz tarafından temin
          edilen ve saklanan kişisel verilerinizin saklandıkları ortamlarda
          yetkisiz erişime maruz kalmamaları, manipülasyona uğramamaları,
          kaybolmamaları ve zarar görmemeleri amacıyla gereken iş süreçlerinin
          tasarımı ile teknik güvenlik altyapı geliştirmeleri uygulanmaktadır.
          Kişisel verileriniz, size bildirilen amaçlar ve kapsam dışında
          kullanılmamak kaydı ile gerekli tüm bilgi güvenliği tedbirleri de
          alınarak işlenecek ve yasal saklama süresince veya böyle bir süre
          öngörülmemişse işleme amacının gerekli kıldığı süre boyunca saklanacak
          ve işlenecektir. Bu süre sona erdiğinde, kişisel verileriniz silinme,
          yok edilme ya da anonimleştirme yöntemleri ile Şirketimizin veri
          akışlarından çıkarılacaktır. Kişisel Verilerin aktarılması; Kişisel
          verileriniz, Kanunlar ve sair mevzuat kapsamında ve açıklanan
          amaçlarla; İşlenip işlenmediğini öğrenme, İşlenmişse bilgi talep etme,
          İşlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme,
          Yurt içinde / yurt dışında aktarıldığı 3. kişileri bilme, Eksik /
          yanlış işlenmişse düzeltilmesini isteme, KVKK’nın 7. maddesinde
          öngörülen şartlar çerçevesinde silinmesini / yok edilmesini isteme,
          Aktarıldığı 3. kişilere yukarıda sayılan (e) ve (f) bentleri uyarınca
          yapılan işlemlerin bildirilmesini isteme, Münhasıran otomatik
          sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya
          çıkmasına itiraz etme, Kanuna aykırı olarak işlenmesi sebebiyle zarara
          uğramanız hâlinde zararın giderilmesini talep etme haklarına
          sahipsiniz. KVK Kanunu’nun 13. maddesinin 1. fıkrası gereğince,
          yukarıda belirtilen haklarınızı kullanmak ile ilgili talebinizi,
          yazılı olarak veya Kişisel Verileri Koruma Kurulu’nun belirlediği
          diğer yöntemlerle Şirketimize iletebilirsiniz. Yukarıda belirtilen
          haklarınızı kullanmak için kimliğinizi tespit edici gerekli bilgiler
          ile iletişim sayfası üzeriden, noter kanalıyla veya Kişisel Verileri
          Koruma Kurulu tarafından belirlenen diğer yöntemler ile
          gönderebilirsiniz.
        </p>
      </div>
    </>
  );
};

export default sozlesme;
