import React from "react";
import Page403 from "./components/errors/Page403";
import Page404 from "./components/errors/Page404";
import Login from "./components/Home/Login";
import Register from "./components/Home/Register";
import Home from "./components/Home/Home";
import Contact from "./components/Home/Contact";
import Category from "./components/frontend/ViewCategory";
import Product from "./components/frontend/ViewProduct";
import Gizlilik from "./components/Common/gizlilik";
import Sozlesme from "./components/Common/sozlesme";
import { Routes, Route} from "react-router-dom";
import axios from "axios";
import AdminPrivateRoute from './AdminPrivateRoute';
import UserPrivateRoute from './UserPrivateRoute';
import FrontendHome from "./components/frontend/FrontendHome";
import { ApiUrl } from "./Constants";


axios.defaults.baseURL= ApiUrl;

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'


axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config){
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
})



function App() {
  return (
    <div className="App">
      <Routes>

        
        <Route path="/" element={<Home/>} />
        <Route path="/403" element={<Page403 />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="/gizlilik-politikasi" element={<Gizlilik />} />
        <Route path="/kullanim-sozlesmesi" element={<Sozlesme />} />
        <Route path="/login" element={<Login />} />
        <Route path="/kayit" element={<Register />} />
        <Route path="/register" element={<Register />} />
        <Route path="/iletisim" element={<Contact />} />
        

        <Route path="qr/:company/" element={<FrontendHome/>} />  
        <Route path="qr/:company/category" element={<Category/>} />        
        <Route path="qr/:company/category/:id" element={<Product/>} />

        {/* Admin Routing */}
        <Route path="/admin/*" name="Admin" element={<AdminPrivateRoute />} />
        {/* User Routing */}
        <Route path="/user/*" name="User" element={<UserPrivateRoute />} />
          
      </Routes>

    </div>
  );
}

export default App;
