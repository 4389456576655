import React from 'react'
import EditCategory from '../../components/categories/EditCategory';
import Sidebar from '../../components/sidebar/Sidebar3'

const Home = () => {
  return (
    <>
    <div className='h-screen flex'>
    <Sidebar />
    <EditCategory/>
    </div>
    


    </>

  )
}

export default Home;