import { LockClosedIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import { useState } from 'react';
import logo from "./../../assets/menubum.png";
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';

export default function Login() {

  const navigate = useNavigate();

  const [loginInput, setLogin] = useState({
    email: '',
    password: '',
    error_list: [],
  });

  const handleInput = (e) => {
    e.persist();
    setLogin({...loginInput, [e.target.name]: e.target.value });
  }

  const loginSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: loginInput.email,
      password: loginInput.password,
    }

    axios.get('/sanctum/csrf-cookie').then(response => {

    axios.post('api/login', data).then(res => {
      if(res.data.status === 200)
      {
        localStorage.setItem('auth_token', res.data.token);
        localStorage.setItem('auth_name', res.data.username);
        swal("Success",res.data.message,"success");
        if(res.data['role'] === 'admin')
        {
          navigate('/admin');
        }
        else if((res.data['role'] === 'user'))
        {
          navigate('/user');
        }
        else{
          navigate('/');
        }
      }
      else if(res.data.status === 401)
      {
        swal("Warning",res.data.message,"warning");
      }
      else
      {
        setLogin({...loginInput, error_list: res.data.validation_errors });
        swal("UYARI",res.data.message,"warning");
      }
    });
  });
  

  }

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
          <img
              className="mx-auto h-20 w-auto"
              src={logo}
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Hesabınıza giriş yapın
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Veya{' '}
              <a href="/register" className="font-medium text-red-600 hover:text-red-500">
                Hemen yeni bir hesap oluşturalım
              </a>
            </p>
          </div>
          <form className="mt-8 space-y-6" onSubmit={loginSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  onChange={handleInput}
                  type="email"
                  autoComplete="email"
                  required
                  value={loginInput.email}
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                  placeholder="E-Posta Adresi"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  onChange={handleInput}
                  type="password"
                  autoComplete="current-password"
                  required
                  value={loginInput.password}
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                  placeholder="Şifre"
                />
              </div>
            </div>

            {/* Bu bölüm kaldırılacak beni hatırla ve Şifrenizi mi unuttunuz özelliği aktif edildiğinde geri getirilecek */}
            {/* <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Beni Hatırla
                </label>
              </div>

              <div className="text-sm">
                <a href="#" className="font-medium text-red-600 hover:text-red-500">
                  Şifrenizi mi unuttunuz?
                </a>
              </div>
            </div> */}

            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-red-500 group-hover:text-red-400" aria-hidden="true" />
                </span>
                Giriş Yap
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
