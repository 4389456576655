import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ApiUrl } from "../../../../Constants";
import imageCompression from "browser-image-compression";

const App = () => {
  const Url = ApiUrl;

  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  function refreshPage() {
    window.location.reload();
  }

  const [categoryInput, setCategory] = useState({
    name: "",
    description: "",
    status: "1",
    error_list: [],
  });

  const [picture, setPicture] = useState([]);




  async function handleImage(event) {
    setPicture({ image: event.target.files[0] });

    const imageFile = event.target.files[0];
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
  
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
  
      await setPicture({ image: compressedFile }); // write your own logic
    } catch (error) {
      console.log(error);
    }
  
  };
  
  const handleInput = (e) => {
    e.persist();
    setCategory({ ...categoryInput, [e.target.name]: e.target.value });
  };

  async function submitCategory(e) {
    e.preventDefault();

    const formData = new FormData();
    if (picture.image) {
      formData.append("image", picture.image);
    }
    formData.append("name", categoryInput.name);
    formData.append("description", categoryInput.description);
    formData.append("status", categoryInput.status);


    axios.post(`/api/store-category`, formData).then((res) => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        refreshPage();
      } else if (res.data.status === 400) {
        setCategory({ ...categoryInput, error_list: res.data.errors });
      }
    });
  };

  const [loading, setLoading] = useState(true);
  const [categorylist, setCategorylist] = useState([]);

  useEffect(() => {
    axios.get(`/api/view-category`).then((res) => {
      if (res.status === 200) {
        setCategorylist(res.data.category);
      }
      setLoading(false);
    });
  }, []);

  const deleteCategory = (e, id) => {
    e.preventDefault();

    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Siliniyor...";

    axios.delete(`/api/delete-category/${id}`).then((res) => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        thisClicked.closest("tr").remove();
      } else if (res.data.status === 404) {
        swal("Warning", res.data.message, "warning");
        thisClicked.innerText = "Sil";
      }
    });
  };

  var viewcategory_HTMLTABLE = "";
  if (loading) {
    return <h4>Loading Category</h4>;
  } else {
    viewcategory_HTMLTABLE = categorylist.map((item) => {
      return (
        <tr className="bg-gray-50" key={item.id}>
          <td className="p-3 text-sm text-gray-700">{item.name}</td>
          <td className="p-3 text-sm text-gray-700">{item.description}</td>          
          <td className="p-3 text-sm text-gray-700">
            {item.image ? (
              <img
              
                src={`${Url}${item.image}`}
                width="50px"
                alt={item.name}
              />
            ) : null}
          </td>        
          <td className="p-3 text-sm text-gray-700">{item.count}</td>
          <td className="p-3 text-sm text-gray-700">{item.status === "1" ? "Aktif" : "Pasif"}</td>
          <td>
            <Link
              to={`edit-category/${item.id}`}
              className="p-1.5 text-xs font-medium tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50"
            >
              Düzenle
            </Link>
          </td>
          
          <td>
            <button
              onClick={(e) => deleteCategory(e, item.id)}
              className="p-1.5 text-xs font-medium tracking-wider text-red-800 bg-red-200 rounded-lg bg-opacity-50"
            >
              Sil
            </button>
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      <div className="min-h-screen flex-1 min-w-0 bg-gray-100">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
          <div className="text-xl mb-2 flex items-center justify-between">
            <h1 className="py-auto">Kategoriler</h1>

            <div className="">
              <button
                type="button"
                onClick={openModal}
                className="flex w-full hover: items-center justify-center rounded-md border-transparent bg-red-600 font-medium text-white hover:bg-red-700 md:py-4 md:px-3 md:text-sm"
              >
                Kategori Ekle
              </button>
            </div>
          </div>

          <div className="rounded-sm">
            <table className="w-full">
              <thead className="bg-gray-50 border-b-2 border-gray-200">
                <tr>

                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Kategori Adı
                  </th>
                  <th className="w-96 p-3 text-sm font-semibold tracking-wide text-left">
                    Açıklama
                  </th>
                  <th className="w-96 p-3 text-sm font-semibold tracking-wide text-left">
                    Ürün Görseli
                  </th>

                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Görüntülenme Sayısı
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Durum
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Düzenle
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Sil
                  </th>
                </tr>
              </thead>

              <tbody>{viewcategory_HTMLTABLE}</tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Kategori Ekleme İşlemi Burada Yapılır */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full py-1 max-w-xl transform overflow-hidden rounded-2xl bg-white p-5 text-left align-middle shadow-xl transition-all">
                  <div className="mt-4 text-right py-2">
                    <button
                      type="button"
                      className=" rounded-md justify-end border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      X
                    </button>
                  </div>
                  <div className="mt-5 sm:mt-0">
                    <div className="">
                      <div className="mt-5 md:col-span-2 md:mt-0">
                        <form
                          onSubmit={submitCategory}
                          id="CATEGORY_FORM"
                          method="POST"
                        >
                          <div className="overflow-hidden shadow sm:rounded-md">
                            <div className="bg-white px-4 py-5 sm:p-6">
                              <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-6">
                                  <label
                                    htmlFor="name"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Kategori Adı*
                                  </label>
                                  <input
                                    type="text"
                                    name="name"
                                    onChange={handleInput}
                                    value={categoryInput.name}
                                    id="name"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                                  />
                                  {categoryInput.error_list.name}
                                </div>

                                <div className="col-span-6">
                                  <label
                                    htmlFor="Descrip"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Açıklama
                                  </label>
                                  <textarea
                                    type="text"
                                    name="description"
                                    onChange={handleInput}
                                    value={categoryInput.description}
                                    id="description"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                                  />
                                  {categoryInput.error_list.description}
                                </div>

                                <div className="col-span-6">
                                  <label
                                    htmlFor="name"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Kategori Fotoğrafı
                                  </label>
                                  <input
                                    type="file"
                                    name="image"
                                    onChange={handleImage}
                                    id="name"
                                    className="mt-1 block w-full  border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                                  />
                                  {categoryInput.error_list.image}
                                </div>

                                <div className="col-span-6">
                                  <label
                                    htmlFor="name"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Durum*
                                  </label>
                                  <select
                                    name="status"
                                    onChange={handleInput}
                                    value={categoryInput.status}
                                    id="name"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                                  >
                                    
                                    <option value="1">Aktif</option>
                                    <option value="0">Pasif</option>
                                  </select>
                                  
                                </div>
                              </div>
                            </div>
                            <div className="bg-gray-50 py-3 justify-center text-right sm:px-36">
                              <button
                                type="submit"
                                className="w-full justify-center rounded-md flex items-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                              >
                                Kategori Ekle
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default App;
