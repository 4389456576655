import {
  BoltIcon,
  ChatBubbleBottomCenterTextIcon,
  GlobeAltIcon,
  ScaleIcon,
  Cog8ToothIcon,
} from "@heroicons/react/24/outline";

const features = [
  {
    id: 1,
    name: "Yüksek Hız",
    description:
      "Artık zaman kaybetmek yok, Menünüz saniyeler içerisinde müşterilerinizin elinde",
    icon: BoltIcon,
  },
  {
    id: 2,
    name: "Doğa Dostu Çözüm",
    description:
      "Dijital Menü ile birlikte kağıt ve plastik tüketiminizi azaltın,baskı maaliyetlerinden kurtulun",
    icon: GlobeAltIcon,
  },
  {
    id: 3,
    name: "Temassız Dijital Menü",
    description:
      "Müşterileriniz ve çalışanlarınız için güvenli ve temassız bir yol sunun",
    icon: Cog8ToothIcon,
  },
  {
    id: 4,
    name: "Kolay Yönetim",
    description:
      "İstediğiniz zaman, istediğiniz yerden menünüzü güncelleyin",
    icon: Cog8ToothIcon,
  },
  {
    id: 5,
    name: "Saniyeler İçerisinde Yeni Bir Menü",
    description:
      "Menünüzde yaptığınız değişikler anında müşterinizin karşısında, fiyat güncellemelerinin, tükenen ürünlerin bildirilmesinin kolay ve hızlı yolu",
    icon: ScaleIcon,
  },
  {
    id: 6,
    name: "Özelleştirmeler",
    description:
      "İşletmenizi daha iyiye taşımanın en iyi yolu müşterilerinizin fikirleri, müşterilerinize anketler ve fırsatlar sunarak etkileşiminizi artırın ve düşüncelerini öğrenin",
    icon: ChatBubbleBottomCenterTextIcon,
  },

];

export default function Features() {
  return (
    <div id="features" className="bg-white py-12">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-lg font-semibold text-red-600">Özellikler</h2>
          <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            Yeni nesil dijital menü
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Dijital menünüz saniyeler içerisinde müşterinizin telefonunda
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
            {features.map((feature) => (
              <div key={feature.id} className="relative">
                <dt>
                  <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-red-500 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}